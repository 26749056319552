<template>
    <div>
        <span v-if="status" class="badge"
                 :class="{'badge-primary': status.level === 1 || !status.level, 'badge-neutral': status.level === 3, 'badge-danger': status.level === 4, 'badge-yellow': status.level === 2}">{{ $t('growing.component.grow.statuses.' + status.name+  '.title') }}</span>
        <span v-if="!status" class="badge badge-secondary">{{$t('growing.view.rooms.status.unknown')}}</span>
    </div>
</template>

<script>
    export default {
        props: ['status']
    }
</script>

<style>

</style>
