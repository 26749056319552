<template>
  <div v-if="plant">
    <div :class="(plant.market && plant.market.is_for_sale ? 'card-plant-disabled': '')" class="position-relative" :style="{opacity: plant.participation ? '0.7' : '1'}">
      <div class="card card-default card-data-table card-plant plant mb-3" :class="'card-plant-level-' + (preUpgrade ? 3 : plant.level)">

        <div class="card-body pb-0">
          <div class="mb-1">
            <img src="../asstes/svg/plant-levels/plant-level-1.svg" width="150px" class="mw-100" v-if="plant.level === 1 && !compact && !preUpgrade">
            <img src="../asstes/svg/plant-levels/plant-level-2.svg" width="150px" class="mw-100" v-if="plant.level === 2 && !compact && !preUpgrade">
            <img src="../asstes/svg/plant-levels/plant-level-3.svg" width="150px" class="mw-100" v-if="(plant.level === 3 && !compact) || preUpgrade">

            <div class="d-inline-block mt-0" v-if="compact">
              <img src="../asstes/svg/plant-levels/plant-level-1.svg" width="60px" class="mw-100 mr-3" v-if="plant.level === 1">
              <img src="../asstes/svg/plant-levels/plant-level-2.svg" width="60px" class="mw-100 mr-3" v-if="plant.level === 2">
              <img src="../asstes/svg/plant-levels/plant-level-3.svg" width="60px" class="mw-100 mr-3" v-if="plant.level === 3">
            </div>
            {{ plant.label }}
            <span v-if="compact && !plant.participation" class="badge badge-secondary">{{ $t('growing.component.plant_browser.table.room') }}: {{ plant.room_name }}</span>
            <span v-if="compact && plant.participation" class="badge badge-secondary">Genussschein-Tausch <br/>wird vorbereitet</span>
          </div>

          <div v-if="!compact">
            <div class="text-center mb-1">
              <span v-if="!plant.participation"  class="badge badge-pill">{{ $t('growing.component.plant_browser.table.room') }}: {{ plant.room_name }}</span>
              <span v-if="plant.participation" class="badge badge-secondary">Genussschein-Tausch <br/>wird vorbereitet</span>
            </div>

            <div class="data-table-body mt-2">
              <b-row class="p-0">
                <b-col><i class="fad fa-clock mr-1"></i>{{ $t('growing.component.plant_browser.table.bought_at') }}</b-col>
                <b-col class="text-right pr-0"><span class="badge badge-primary">{{ plant.created_at | moment('utc', 'L') }}</span>
                </b-col>
              </b-row>
                <template v-if="plant.is_locked_until">
                  <b-row class="p-0" v-if="$moment.utc(plant.is_locked_until).diff($moment(), 'days') <= 7">
                    <b-col><i class="fad fa-lock mr-1"></i>{{ $t('growing.component.plant_browser.table.locked_for') }}</b-col>
                      <b-col class="text-right pr-0">
                          <span class="badge badge-danger">{{ $moment.utc(plant.is_locked_until).local().fromNow(true) }}</span>
                      </b-col>
                  </b-row>
                  <b-row class="p-0" v-else>
                    <b-col><i class="fad fa-lock mr-1"></i>{{ $t('growing.component.plant_browser.table.locked_until') }}</b-col>
                    <b-col class="text-right pr-0">
                        <span class="badge badge-danger">{{ $moment.utc(plant.is_locked_until).local().format('L') }}</span>
                    </b-col>
                  </b-row>
                </template>
            </div>
          </div>
        </div>

        <div class="card-footer p-0 text-center" v-if="!compact">
          <b-btn v-if="plant.market && plant.market.is_sellable"
                 @click="sell()"
                 variant="primary"
                 class="float-right bg-white px-1 py-0 m-1 border-0 position-relative"><i
              class="fad fa-hand-holding-usd fa-2x"></i>
          </b-btn>
          <b-btn v-if="plant.is_transferable"
                 @click="transfer()"
                 variant="primary"
                 class="float-right bg-white px-1 py-0 m-1 border-0 position-relative"><i class="fad fa-exchange fa-2x"></i>
          </b-btn>
        </div>
      </div>

      <div class="card-body-market text-center" v-if="plant.market && plant.market.is_for_sale">

        <div v-if="plant.market.status === 'for_sale'">
          <span>Plant is for sale!</span>
          <div class="price">{{ plant.market.price | currency }} EUR</div>
          <b-btn class="mt-3" @click="removePlantFromMarket()">{{ $t('growing.component.plant_browser.market.remove') }}</b-btn>
        </div>

        <div v-if="plant.market.status === 'waiting_for_payment' || plant.market.status === 'waiting_for_payment_confirmation'">
          <span class="mr-2">{{ $t('growing.component.plant_browser.market.waiting_payment') }}<br/>{{ $t('growing.component.plant_browser.market.from_user', {username: plant.market.interest.username}) }}<br/></span>

          <br/><b-btn variant="secondary" :to="{name: 'Chat:Conversation', params: {conversationId: plant.market.interest.conversation_id}}"><i class="fad fa-comments-alt mr-1"></i> {{ $t('growing.component.plant_browser.market.open_chat') }}</b-btn>

          <hr/>

          <div class="price">{{ plant.market.price | currency }} EUR</div>
          <div class="price" v-show="plant.market.payment_method === 'bitcoin'">{{ plant.market.interest.price_btc }} BTC</div>

          <b-btn class="mt-3" variant="primary" @click="confirmPayment()">{{ $t('growing.component.plant_browser.market.confirm_payment') }}</b-btn>
        </div>

      </div>
    </div>
  </div>

</template>

<style lang="scss">
.card-body-market {
  position: absolute;
  top: 30px;
  z-index: 1;
  width: 100%;

  .price {
    font-size: 1.3em;
    font-weight: bold;
  }
}
</style>
<script>
import GrowStatus from "./GrowStatus";

export default {
  components: {GrowStatus},
  props: {
    loading: false,
    plant: {},
    compact: {
      default: false
    },
    upgradeable: {
      default: false
    },
    preUpgrade: {
      default: false
    }
  },
  methods: {
    removePlantFromMarket() {
      this.$set(this.plant.market, 'is_for_sale', false);
      this.$set(this.plant.market, 'is_sellable', true);
      this.$api.delete('growing/plants/' + this.plant.label + '/offer');
    },
    confirmPayment() {
      this.$swal.fire({
        title: this.$t('growing.component.plant_browser.market.popup.payment_confirmation.title'),
        text: this.$t('growing.component.plant_browser.market.popup.payment_confirmation.text'),
        confirmButtonText: this.$t('growing.component.plant_browser.market.popup.payment_confirmation.confirm'),
        cancelButtonText: this.$t('growing.component.plant_browser.market.popup.payment_confirmation.cancel'),
        showCancelButton: true,
      }).then((response) => {
        if (response.value) {
          this.$auth.requestVerfificationCode().then(code => {
            this.$api.post('growing/market/offers/' + this.plant.market.interest.offer_id + '/interest/confirm-payment', {
              verification_code: code.verification_code
            });
            this.$emit('plant-sold');
          });
        }
      })
    },
    transfer() {
      this.$swal.fire({
        title: 'Plant Transfer',
        html: 'You\'re going to transfer the plant: <br/><strong>' + this.plant.label + ' from room ' + this.plant.room_name + '</strong><br/><br/>' +
            'You can transfer a plant once a grow to another user, the Plant will be disqualified for the current grow, the receiver won\'t get a harvest from it.'
            + '<br/><strong>' + 'If you sold your Plant to someone else, only confirm this transfer if you received the money. All transfers are final!' + '</strong>',
        input: 'text',
        inputPlaceholder: 'Enter username here',
        confirmButtonText: 'Confirm Transfer <i class="fad fa-exclamation-triangle"></i>',
        showCancelButton: true,
        reverseButtons: true
      }).then((response) => {
        if (response.value) {
          this.$auth.requestVerfificationCode().then(code => {
            return this.$api.post('growing/plants/' + this.plant.label + '/transfer', {
              username: response.value,
              verification_code: code.verification_code
            })
          }).then(() => {
            this.$swal.fire({
              title: this.$t('growing.component.plant_browser.market.popup.payment_confirmed.title'),
              text: this.$t('growing.component.plant_browser.market.popup.payment_confirmed.text')
            })

            this.plant = null;
          }).catch(response => {
            this.$swal.fire('Failed', response.data.message);
          })
        }
      });
    },
    sell() {
      this.$swal.fire({
        title: this.$t('growing.component.plant_browser.market.popup.about.title'),
        text: this.$t('growing.component.plant_browser.market.popup.about.text')
      }).then(() => {
        return this.$swal.fire({
          title: this.$t('growing.component.plant_browser.market.popup.setup.title'),
          text: this.$t('growing.component.plant_browser.market.popup.setup.text'),
          input: 'number',
          showCancelButton: true,
          reverseButtons: true
        });
      }).then(response => {
        if (response.value) {
          let price = response.value;
          this.$auth.requestVerfificationCode().then(code => {
            return this.$api.post('growing/plants/' + this.plant.label + '/offer', {
              price: price,
              verification_code: code.verification_code
            })
          }).then(() => {
            this.$swal.fire({
              title: this.$t('growing.component.plant_browser.market.popup.setup_completed.title'),
              text: this.$t('growing.component.plant_browser.market.popup.setup_completed.text')
            })

            this.$set(this.plant.market, 'is_for_sale', true);
            this.$set(this.plant.market, 'is_sellable', false);
            this.$set(this.plant.market, 'price', price);

          }).catch(response => {
            this.$swal.fire('Failed', response.data.message);
          })
        }
      })
    }
  }
}
</script>
