<template>
    <div class="container">
        <plant-browser></plant-browser>
    </div>
</template>
<script>
    import PlantBrowser from '@/modules/Growing/components/PlantBrowser.vue';

    export default {
        components: {
            PlantBrowser
        },
    }
</script>
