<script>
    export default {
        props: ['preload'],
        data() {
            return {
                endpoint: '/wallet/transactions',
                endpointParams: {},
                title: 'test',
                state: {
                    data: [],
                    meta: {
                        current_page: 1
                    }
                },
                loading: true,
                members: [],
                pages: 0
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init: function () {
              if (this.preload) {
                this.state = this.preload;
              } else {
                this.refresh();
              }
            },
            nextPage: function () {
                this.state.meta.current_page++;
                this.refresh();
            },
            prevPage: function () {
                this.state.meta.current_page--;
                this.refresh();
            },
            refresh: function () {
                this.loading = true;
                let url = this.endpoint;
                this.endpointParams.page = this.state.meta.current_page;

                return this.$api.get(url, this.endpointParams)
                    .then(response => {

                        this.state = response.data;

                        //    let startPage = 1;
                        //   if (this.state.page > 3) {
                        //     startPage = this.state.page - 2;
                        //   }

                        // this.state.pages = [startPage];
                        /*if (startPage + 1 <= this.pages) this.state.pages.push(startPage + 1);
                        if (startPage + 2 <= this.pages) this.state.pages.push(startPage + 2);
                        if (startPage + 3 <= this.pages) this.state.pages.push(startPage + 3);
                        if (startPage + 4 <= this.pages) this.state.pages.push(startPage + 4);
*/
                        this.loading = false;

                        return response;
                    })
            }
        }
    }
</script>
