<template>
    <div class="card card-default mb-3 animated-fast fadeInUp" :class="loading ? 'whirl' : ''">
        <div class="card-header bg-gray-lighter text-bold">{{$t('growing.component.plant_browser.heading')}}</div>
        <div class="card-body">
            <b-row class="mb-3">
                <b-col sm="5">
                    {{$t('growing.component.plant_browser.description')}}
                </b-col>
                <b-col sm="7">
                    <div class="form-inline">
                        <div class="form-group ml-auto mr-3">
                            <select class="custom-select custom-select-sm" v-model="endpointParams.room"
                                    @change="refresh()">
                                <option value="undefined" selected="">{{$t('growing.component.plant_browser.location.undefined')}}</option>
                                <option :value="id" v-for="(room, id) in this.state.rooms">{{ room}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <ul class="pagination mb-0">
                                <li class="page-item"><span class="page-link" @click="prevPage" href=""><i
                                        class="fad fa-arrow-left"></i></span></li>
                                <li class="page-item" :class="{active: page === state.page}"
                                    v-for="page in state.pages"><span class="page-link">{{page}}</span></li>
                                <li class="page-item active"><span class="page-link">{{state.meta.current_page}}</span>
                                </li>
                                <li class="page-item"><span class="page-link" @click="nextPage" href=""><i
                                        class="fad fa-arrow-right"></i></span></li>
                            </ul>
                        </div>
                    </div>
                </b-col>
            </b-row>


            <b-row>
                <b-col sm="6" md="6" lg="4" xl="3" v-for="(plant, index) in state.data" :key="plant.key" v-show="!selectedPlant || selectedPlant.label === plant.label" >
                    <plant-overview-card @plant-sold="delete state.data[index]" :plant="plant" :upgradeable="plant.level < 3 && !state.has_upgradeable_plants"></plant-overview-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import TableDataBrowser from '@/modules/Wallet/components/TableDataBrowser.vue';
    import PlantOverviewCard from "./PlantOverviewCard";

    export default {
        components: {PlantOverviewCard},
        extends: TableDataBrowser,
        props: ['preSelectType'],
        data() {
            return {
                endpoint: '/growing/plants',
                title: 'Plants',
                icon: 'fad fa-book',
                types: [],
                view: {
                    compact: false
                },
                selectedPlant: null,
                selectedType: undefined
            }
        },
        methods: {
            init() {
                this.refresh();
            },
        }
    }
</script>
